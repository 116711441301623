import * as React from "react"

import { Link, StaticQuery, graphql } from "gatsby"


import Layout from "../components/layout"
import Seo from "../components/seo"

import {Row, Col} from "react-bootstrap"

const Projects = () => (
  <Layout>

  </Layout>
)

export default Projects